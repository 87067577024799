import React from "react"
import { graphql } from "gatsby"
import useSiteTreeContext from "silverstripe-gatsby-helpers/lib/hooks/useSiteTreeContext"
import Layout from "../Page"
import SEOTags from "../../components/SEOTags"
import Breadcrumbs from "../../components/Breadcrumbs"

const Page = ({ data: { silverStripeDataObject }}) => {
	const { title, content } = silverStripeDataObject.SilverStripeSiteTree;

	const  {getChildren, isLevel}  = useSiteTreeContext();
	const children = getChildren();
	const isLevel2 = isLevel(2);
	const hasSubnav = isLevel(2) || !!children.length;

	return (
	  <Layout>
	    <SEOTags pageTitle={title} />

		<div className={`content ${hasSubnav ? 'hasSidebar' : ''}`}>
			<div className="main">
				{isLevel2 && 
					<Breadcrumbs />
				}
	    		<h1 className="font-bold text-2xl mb-4">{title}</h1>
	    		<div className="font-serif htmltext" dangerouslySetInnerHTML={{__html: content}} />
			</div>
		</div>
	  </Layout>
  	);
};

export default Page

export const pageQuery = graphql`
	query ($link: String!) {
		silverStripeDataObject(link: { eq: $link }) {
			SilverStripeSiteTree {
				title
				content
			}
		}

	}
`;